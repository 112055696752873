import React, { useState } from "react"
import _ from "lodash"
import axios from "axios"

export default function GeneralForm() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [errors, setErrors] = useState({})

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)

  function clearInputs() {
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
  }

  const validationForEmail = email => {
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(email)
  }

  async function handleFormSubmit(e) {
    e.preventDefault()
    setIsSubmitting(true)
    let errors = {}

    if (!validationForEmail(email)) {
      errors.email = "Invalid Email Address"
    }

    if (_.isEmpty(name)) {
      errors.name = "Name is required!"
    }

    if (_.isEmpty(phone)) {
      errors.phone = "Phone number is required!"
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
    } else {
      const data = { name, email, phone, message }

      const res = await axios.post(
        "http://school-live.com/api/public/server.php/general",
        data
      )

      setIsSubmitting(false)
      clearInputs()

      if (res.status === 200) {
        console.log("registration successful")

        setIsSuccessful(true)

        setTimeout(() => {
          setIsSuccessful(false)
        }, 3000)
      } else {
        console.log("registration failed")
        setIsSuccessful(false)
      }
    }
  }

  return (
    <form className="connect__form" onSubmit={handleFormSubmit}>
      <input
        name="name"
        value={name}
        type="text"
        onChange={e => {
          setName(e.target.value)
          setErrors({ ...errors, [e.target.name]: null })
        }}
        className={`connect__form__input ${
          errors.name && " connect__form__input--error"
        }`}
        placeholder="Your Name"
      />
      {errors.name && (
        <span className="connect__form__err-txt">{errors.name}</span>
      )}
      <input
        name="email"
        value={email}
        type="text"
        onChange={e => {
          setEmail(e.target.value)
          setErrors({ ...errors, [e.target.name]: null })
        }}
        className={`connect__form__input ${
          errors.email && " connect__form__input--error"
        }`}
        placeholder="Your Email"
      />
      {errors.email && (
        <span className="connect__form__err-txt">{errors.email}</span>
      )}
      <input
        type="text"
        name="phone"
        value={phone}
        className={`connect__form__input ${
          errors.phone && " connect__form__input--error"
        }`}
        placeholder="Phone"
        onChange={e => {
          setPhone(e.target.value)
          setErrors({ ...errors, [e.target.name]: null })
        }}
      />
      {errors.phone && (
        <span className="connect__form__err-txt">{errors.phone}</span>
      )}

      <textarea
        value={message}
        name="message"
        type="text"
        className="connect__form__textArea"
        placeholder="Message"
        onChange={e => {
          setMessage(e.target.value)
          setErrors({ ...errors, [e.target.name]: null })
        }}
      />

      <button
        className="connect__form__submit connect__form__submit--general"
        type="submit"
      >
        {!isSubmitting ? "Submit" : "Submitting..."}
      </button>

      {isSuccessful && (
        <div className="connect__form__success">Submitted Successfully</div>
      )}
    </form>
  )
}
